import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { AuthService, UserType } from './services/auth.service';
import { Observable } from 'rxjs';
import { IframeGuardService } from './services/iframe.guard.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    NgIf,
    AsyncPipe,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  
  
  user$: Observable<UserType>;

  constructor(
    public authService: AuthService,
    private iframeGuardService: IframeGuardService,
  ) {
    this.user$ = this.authService.user$;

    // if (!this.iframeGuardService.isInIframe()) {
    //   window.location.href = 'https://www.alianza.com.bo';
    // }
  }
}
